import { render, staticRenderFns } from "./shopAssistant.vue?vue&type=template&id=200fd70d&scoped=true"
import script from "./shopAssistant.vue?vue&type=script&lang=js"
export * from "./shopAssistant.vue?vue&type=script&lang=js"
import style0 from "./shopAssistant.vue?vue&type=style&index=0&id=200fd70d&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "200fd70d",
  null
  
)

export default component.exports